.main-pagination {
  margin-top: 30px;
  padding-top: 8px;
  border-top: 1px solid rgba(163, 163, 163, .3);

  a {
    text-decoration: none;
  }

  .page-numbers {
    padding: 10px 10px 5px 10px;
    background-color: $main-alternate-color;
    color: #fff;

    &.current {
      background-color: $main-primary-color;
    }
  }
}