.main-section {
	display: block;
	position: relative;
	margin-top: 70px;
	
	@media(max-width: 767px) {
		margin-top: 50px;
	}
	
	.block-content-start, .block-smaller {
		width: 46%;
		@media(max-width: 1200px) {
			width: auto;
		}
	}
	
	h2, .main-set-h1 {
		font-size: 42px;
		line-height: normal;
		text-transform: uppercase;
		color: $main-primary-color;
		font-weight: 700;
		margin-bottom: 25px;
		overflow: hidden;
		position: relative;
		padding-top: 10px;
		
		&::after {
			content: "";
			position: absolute;
			display: inline-block;
			width: 100%;
			height: 1px;
			margin-left: 6%;
			background-color: $main-primary-color;
			bottom: 10px;
		}
		
		@media(max-width: 767px) {
			font-size: 28px;
			line-height: normal;
		}

		&.no-decor {
			&::after {
				display: none;
			}
		}
	}
	
	h3 {
		font-size: 42px;
		line-height: normal;
		text-transform: uppercase;
		color: $main-primary-color;
		font-weight: 700;
		margin-bottom: 20px;
		
		@media(max-width: 767px) {
			font-size: 28px;
			line-height: normal;
		}
	}

	p+h3 {
		margin-top: 30px;
	}

	h4 {
		font-weight: 700;
		font-size: 30px;
		margin-bottom: 25px;
		text-transform: uppercase;
	}

	.post-full-thumb {
		float: right;
		margin: 0 0 15px 30px;
		max-width: 637px;
		@media(max-width: 767px) {
			max-width: unset;
			float: unset;
			margin: 0 0 15px 0;
		}
	}
	
	.main-high-text {
		font-size: 24px;
		line-height: 35px;
		font-weight: 700;
		
		span {
			color: $main-primary-color;
		}
	}

	.row.main-ntch {
		& > div {
			& {
				padding-top: 5px;
				padding-bottom: 5px;
			}
			&:nth-child(even) {
				background-color: $main-secondary-color;
			}
		}
	}
	
	&.section-type-a {
		
		.sub-section {
			position: relative;
			background-color: $main-secondary-color;
			padding-top: 120px;
			padding-bottom: 120px;
			
			@media(max-width: 767px) {
				padding-top: 50px;
				padding-bottom: 50px;
			}
			
			.img-abs {
				position: absolute;
				right: 0;
				bottom: 0;
				
				@media(max-width: 767px) {
					display: none;
				}
			}
			
			.col-z-index {
				position: relative;
				z-index: 2;
				
				@media(max-width: 1520px) {
					background-color: rgba(255, 255, 255, .5);
					padding-top: 4px;
					padding-bottom: 4px;
				}
				
				@media(max-width: 767px) {
					background-color: transparent;
					padding-top: 0;
					padding-bottom: 0; 
				}
			}
			
			&.sub-section-a {
				margin-top: 140px;
				margin-bottom: 2px;
				min-height: 552px;
				
				@media(max-width: 767px) {
					min-height: unset;
					margin-top: 50px;
				}
			}
			
			&.sub-section-b {
				z-index: 2;
				
				.img-abs {
					bottom: -100px;
					right: auto;
					
					@media(max-width: 1520px) {
						right: -100px;
					}
				}
			}
			
			&.sub-section-c {
				background-color: transparent;
				padding-top: 80px;
				
				@media(max-width: 767px) {
					padding-top: 50px;
				}
				
				.img-abs {
					left: 0;
					bottom: auto;
					top: 0;
				}
			}
		}
	}
	
	&.section-type-f {
		margin-top: 50px;
	}
	
}