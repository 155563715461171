@import url("https://use.typekit.net/yeh4bcb.css");

@font-face {
  font-family: 'ChevinPro';
  font-display: optional;
  src: url('../fonts/chevinpro-medium_0-webfont.woff2') format('woff2'),
       url('../fonts/chevinpro-medium_0-webfont.woff') format('woff');
}

@font-face {
  font-family: bootstrap-icons;
  font-display: optional;
}