.main-btn-header {
	position: absolute;
	left: 0;
	bottom: -104px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-decoration: none;
	padding: 20px;
	width: 220px;
	background-color: #fff;
	color: $main-primary-color;
	text-transform: uppercase;
	font-size: 18px;
	line-height: 26px;
	font-weight: 700;
	
	&::before {
		content: "";
		position: absolute;
		left: 2px;
		top: 25%;
		width: 0;
		height: 50%;
		background-color: $main-primary-color;
		transition: all .3s ease;
	}
	
	&:hover, &:focus {
		color: $main-primary-color;
		text-decoration: none;
		
		&::before {
			width: 15px;
			transition: all .3s ease;
		}
		
		.btn-title {
			transition: all .3s ease;
			margin-left: 15px;
		}
		
		.btn-decor {
			width: 34px;
			height: 34px;
			font-size: 24px;
			line-height: 24px;
			margin-right: -5px;
			top: calc(50% - 17px);
			transition: all .3s ease;
		}
	}
	
	.btn-title {
		transition: all .3s ease;
		margin-top: 2px;
	}
	
	.btn-decor {
		position: absolute;
		right: 20px;
		top: calc(50% - 12px);
		transition: all .3s ease;
		color: #fff;
		background-color: $main-primary-color;
		padding: 2px 0 0 0;
		width: 24px;
		height: 24px;
		font-size: 16px;
		line-height: 16px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	
	& > div {
		display: inline-block;
	}
	
	@media(max-width: 767px) {
		padding: 10px;
		width: 160px;
		bottom: -74px;
		margin-left: -11px;
	}
}

.main-btn-one {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-decoration: none;
	padding: 20px;
	width: 220px;
	background-color: $main-primary-color;
	color: #fff;
	text-transform: uppercase;
	font-size: 18px;
	line-height: 26px;
	font-weight: 700;
	border: none;
	position: relative;
	
	&::before {
		content: "";
		position: absolute;
		left: 2px;
		top: 25%;
		width: 0;
		height: 50%;
		background-color: #fff;
		transition: all .3s ease;
	}
	
	&:hover, &:focus {
		color: #fff;
		text-decoration: none;
		
		&::before {
			width: 15px;
			transition: all .3s ease;
		}
		
		.btn-title {
			transition: all .3s ease;
			margin-left: 15px;
		}
		
		.btn-decor {
			width: 34px;
			height: 34px;
			font-size: 24px;
			line-height: 24px;
			margin-right: -5px;
			top: calc(50% - 17px);
			transition: all .3s ease;
		}
	}
	
	.btn-title {
		transition: all .3s ease;
		margin-top: 2px;
	}
	
	.btn-decor {
		position: absolute;
		right: 20px;
		top: calc(50% - 12px);
		transition: all .3s ease;
		color: $main-primary-color;
		background-color: #fff;
		padding: 2px 0 0 0;
		width: 24px;
		height: 24px;
		font-size: 16px;
		line-height: 16px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	
	& > div {
		display: inline-block;
	}
}

a {
	color: $main-primary-color;

	&:hover, &:focus {
		color: $main-primary-color;
		text-decoration: none;
	}
}