.blocks-type-b {
	margin-top: 40px;
	margin-bottom: 40px;
	
	.block-item {
		padding: 50px 15px;
		background-color: $main-secondary-color;
		
		@media(max-width: 767px) {
			margin-bottom: 20px;
		}
		
		.cnt-number {
			font-size: 88px;
			line-height: normal;
			color: $main-primary-color;
			font-weight: 700;
			text-transform: uppercase;
			position: relative;
			margin-bottom: 140px;
			text-align: center;
			
			@media(max-width: 1200px) {
				font-size: 60px;
			}
			
			@media(max-width: 767px) {
				font-size: 50px;
				margin-bottom: 60px;
			}
			
			&::after {
				content: "";
				position: absolute;
				width: 100px;
				height: 2px;
				left: calc(50% - 50px);
				bottom: -20px;
				background-color: rgba(19, 71, 213, .6);
			}
		}
		
		.cnt-title {
			font-size: 24px;
			line-height: 35px;
			font-weight: 700;
			text-align: center;
		}
	}
}