.main-text-left {
	text-align: left;
}

.main-text-right {
	text-align: right;
}

.main-text-color-primary {
	color: $main-primary-color !important;
}

.main-text-color-secondary {
	color: $main-secondary-color !important;
}

.main-bg-color-primary {
	background-color: $main-primary-color !important;
}

.main-bg-color-secondary {
	background-color: $main-secondary-color !important;
}

p {
	&:last-child {
		margin-bottom: 0;
	}
}

.alignleft {
	float: left !important;
	margin: 0 30px 15px 0;
}

.alignright {
	float: right !important;
	margin: 0 0 15px 30px;
}

.main-text-blue {
	color: $main-primary-color;
	font-weight: 700;
}

.main-width-50, span.main-width-50 {
	width: 50%;
	display: block;
	@media(max-width: 767px) {
		width: 100%;
	}
}

.main-big-padding-bottom {
	padding-bottom: 50px;
}

.main-bottom-line {
	position: relative;
	
	&::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: -20px;
		width: 100px;
		height: 1px;
		background-color: $main-primary-color;
	}
}