body {
	overflow-x: hidden;
	font-size: $main-primary-font-size;
	line-height: $main-primary-line-height;
	font-family: $main-primary-font-family;
	font-display: optional;
	color: $main-body-text-color;
	font-weight: 400;
	background-color: #fff;
	
	@media(max-width: 767px) {
		font-size: 16px;
		line-height: normal;
	}
}