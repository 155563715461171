.form-contact {
	color: #fff;
	
	fieldset {
		border-top: 2px inset $main-secondary-color;
		border-bottom: 2px outset $main-secondary-color;
		border-left: 1px outset #fff;
		border-right: 1px inset #fff;
		padding: 7px 12px 12px 12px;
		margin-top: 20px;
		
		legend {
			padding-left: 10px;
			padding-right: 10px;
			margin-bottom: 0;
			float: none;
			width: auto;
			font-size: 14px;
			line-height: normal;
			font-weight: 700;
		}
	}
	
	input, textarea {
		border: none;
		color: #fff;
		background-color: #2B2B33;
		
		&:hover {
			color: #fff;
			background-color: #2B2B33;
		}
		
		&:focus {
			color: #fff;
			background-color: $main-primary-color-rgba;
		}

		&+span {
			display: block;
			padding: 4px 4px 2px 4px;
			background-color: $danger;
			color: #fff;
			font-size: 12px;
			line-height: normal;
			font-weight: 400;
		}
	}

	&.form-contact-light {
		color: $main-body-text-color;
		fieldset {
			border-top: 2px solid #A3A3A3;
			border-bottom: 2px solid #A3A3A3;
			border-left: 2px solid #A3A3A3;
			border-right: 2px solid #A3A3A3;
		}
		input, textarea {
			color: $main-primary-color;
			background-color: #fff;
			&:hover {
				color: $main-primary-color;
				background-color: #fff;
			}
			&:focus {
				color: $main-primary-color;
				background-color: #fff;
			}
		}
	}
}