$main-primary-color: #1347D5;
$main-primary-color-rgba: rgba(19, 71, 213, .6);
$main-secondary-color: #F5F5F5;
$main-alternate-color: #161515;
$main-body-text-color: #161515;
$main-primary-font-family: fira-sans, sans-serif;
$main-alternate-font-family: Arial, Helvetica, sans-serif;
$main-font-family-3: 'ChevinPro', sans-serif;
$main-primary-font-size: 20px;
$main-primary-line-height: 35px;

//Background div body
$main-div-body-padding-top: 0;