footer {
	display: block;
	width: 100%;
	padding-top: 80px;
	background-color: #2B2B33;
	color: #fff;
	margin-top: 30px;
	
	h2, .h2 {
		display: block;
		color: #fff;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 28px;
		line-height: normal;
		margin-bottom: 20px;
		
		@media(max-width: 767px) {
			&:not(.main-first) {
				margin-top: 40px;
			}
		}
	}
	
	ul.menu-footer {
		margin: 0;
		padding: 15px 25px;
		border-left: 2px solid $main-primary-color;
		list-style-type: none;
		font-size: 24px;
		line-height: normal;
		
		& > li {
			padding-top: 15px;
			padding-bottom: 15px;
			
			@media(max-width: 767px) {
				padding-top: 5px;
				padding-bottom: 5px;
			}
			
			& > a {
				font-size: 24px;
				line-height: normal;
				text-decoration: none;
				color: #fff;
				
				@media(max-width: 767px) {
					font-size: 20px;
				}
				
				&:hover, &:focus {
					text-decoration: underline;
					color: $main-primary-color;
				}
			}

			& > ul {
				display: none;
			}
		}
	}
	
	a {
		color: #fff;
		
		&:hover, &:focus {
			color: $main-primary-color;
		}
	}
	
	.sub-footer-one {
		width: 100%;
		margin-top: 40px;
		background-color: #121213;
		padding: 40px 15px;
		font-size: 24px;
		line-height: normal;
		font-weight: 700;
		
		@media(max-width: 767px) {
			font-size: 20px;
		}
		
		a {
			&[href^="tel:"] {
				font-size: 42px;
				line-height: normal;
				font-weight: 700;
				text-decoration: none;
				
				@media(max-width: 767px) {
					font-size: 28px;
				}
				
				&:hover, &:focus {
					text-decoration: underline;
				}
			}
			&[href^="mailto:"] {
				color: $main-primary-color;
				font-weight: 400;
				
				&:hover, &:focus {
					text-decoration: none;
				}
			}
		}
		
		.main-ft-emails {
			margin-top: 12px;
			@media(max-width:767px) {
				float: left !important;
			}
		}
		
		.main-specific {
			padding-top: 33px;
		}
		
		.main-specific-two {
			padding-top: 5px;
		}
	}
	
	.sub-footer-two {
		width: 100%;
		background-color: #000000;
		padding-top: 20px;
		padding-bottom: 20px;
		font-size: 14px;
		line-height: normal;
		
		@media(max-width: 767px) {
			font-size: 12px;
		}
		
		.col-copyrights-2 {
			float: right;
			
			@media(max-width: 767px) {
				float: none;
				margin-top: 15px;
			}
			
			a {
				text-decoration: none;
			}
		}
		
		a {
			color: $main-primary-color;

			&:hover, &:focus {
				color: $main-primary-color;
				text-decoration: none;
			}
		}
	}
}