@import 'vars_frameworks';

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import '../../node_modules/slick-carousel/slick/slick';
$slick-font-path: "../../node_modules/slick-carousel/slick/fonts/";
$slick-loader-path: "../../node_modules/slick-carousel/slick/";
@import '../../node_modules/slick-carousel/slick/slick-theme';

@import "../../node_modules/slicknav/scss/slicknav";


//customs
@import 'fonts';
@import 'variables';
@import 'general';
@import 'header';
@import 'body';
@import 'main_div_body';
@import 'animations';
@import 'buttons_and_links';
@import 'sections';
@import 'lists';
@import 'menu_top';
@import 'slider_header';
@import 'blocks_type_a';
@import 'blocks_type_b';
@import 'blocks_type_c';
@import 'carousel_type_a';
@import 'carousel_type_b';
@import 'main_blog_posts';
@import 'main_refs_posts';
@import 'accordion';
@import 'footer';
@import 'form_contact';
@import 'slicknav';
@import 'main_nav_top_sticky';
@import 'main_pagination';
@import 'gallery';
@import 'main-error';
@import 'map-poland';
@import 'blocks_others';
@import 'breadcrumb';
@import 'header_intro';