#main-menu-top {
	position: relative;
	z-index: 6;
	width: 100%;
	height: 104px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	border-bottom: 1px solid #fff;
	transition: height .3s ease;
	transition: border-bottom .3s ease;

	//background-color: rgba(19, 71, 213, .2);
	backdrop-filter: blur(5px);
	
	@media(max-width: 767px) {
		display: none;
	}
	
	& > ul {
		display: block;
		margin: 0;
		padding: 0 15px 0 0;
		list-style-type: none;
		& > li {
			display: inline-block;
			position: relative;
			& > a {
				font-size: 18px;
				line-height: 26px;
				font-weight: 700;
				color: #fff;
				text-transform: uppercase;
				text-decoration: none;
				padding: 17px 15px 13px 15px;
				transition: background-color .3s ease;
				text-shadow: 0 0 3px rgba(19, 71, 213, .9);
				
				@media(max-width: 1000px) {
					font-size: 16px;
					line-height: normal;
					padding: 5px 5px 2px 5px;
				}
				
				&:hover, &:focus {
					transition: background-color .3s ease;
					background-color: rgba(255, 255, 255, .7);
					color: $main-primary-color;
					text-shadow: none;
				}
			}

			& {
				& > a {
					border-top: 2px solid rgba(255, 255, 255, 0);
				}
				&.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
					& > a {
						border-top: 2px solid rgba(255, 255, 255, .7);
					}
				}
				& > ul {
					display: none;
					position: absolute;
					top: 40px;
					width: 250px;
					list-style-type: none;
					margin: 0;
					padding: 25px 15px 10px 15px;
					background-color: rgba(11, 45, 141, 0.95);
					color: #fff;
					&::before {
						content: "\f279";
						font-family: bootstrap-icons !important;
						position: absolute;
						top: -8px;
						left: 15px;
						color: rgba(255, 255, 255, 1);
						font-size: 36px;
						line-height: normal;
					}
					& > li {
						padding-top: 8px;
						padding-bottom: 8px;
						& > a {
							display: block;
							color: #fff;
							text-decoration: none;
							font-size: 18px;
							line-height: normal;
							transition: all .2s;
							&:hover, &:focus {
								text-decoration: underline;
								color: rgb(173, 207, 252);
								transition: all .2s ease;
							}
						}
					}
				}
				&:hover, &:focus-within {
					& > ul {
						display: block;
					}
				}
			}
		}
	}
}