.gallery {
  margin-top: 50px;
  position: relative;
  z-index: 1;

  .gallery-item {
    width: 100%;
    padding: 15px;
    margin-top: 50px;
    margin-bottom: 30px;
    background-color: $main-secondary-color;
    min-height: 313px;

    @media(max-width: 767px) {
      min-height: unset;
      margin-top: 20px;
    }

    .gallery-icon {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 160px;

      img {
        max-width: 300px;
        max-height: 200px;
        width: auto;
        height: auto;
      }
    }

    .gallery-it-title {
      transition: margin-left .3s ease;
      font-size: 22px;
      line-height: 26px;
      font-weight: 700;
      color: #000;
      position: relative;
      overflow: hidden;
      margin-top: 30px;
      margin-bottom: 20px;

      @media(max-width: 767px) {
        font-size: 22px;
        line-height: normal;
      }

      &::after {
        transition: margin-left .3s ease;
        content: "";
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 1px;
        margin-left: 30%;
        background-color: $main-primary-color;
        bottom: 7px;
      }
    }

    .wp-caption-text {
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 15px;

      @media(max-width: 767px) {
        font-size: 16px;
        line-height: normal;
      }
    }
  }
}