header {
	display: block;
	position: relative;
	width: 100%;

	@media(max-width: 767px) {
		margin-top: 54px;
	}
	
	#logo-container {
		position: absolute;
		z-index: 7;
		top: 0;
		left: 0;
		width: 26.5%;
		height: 104px;
		padding: 15px 30px;
		background-color: rgba(255, 255, 255, 1);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		@media(max-width: 767px) {
			display: none;
		}
		& > a {
			display: block;
			position: relative;
			z-index: 8;
		}
	}
}