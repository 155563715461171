#slider-header {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	
	.carousel-inner {
		
		.carousel-item {
			
			.carousel-caption {
				z-index: 1;
				max-width: 950px;
				max-height: 350px;
				text-align: left;
				left: 0;
				bottom: 25%;
				right: auto;
				padding: 30px;
				background-color: rgba(19, 71, 213, .6);
  				backdrop-filter: blur(17px);
				
				& > .main-after {
					position: absolute;
					width: 40px;
					height: 40px;
					right: -15px;
					top: -15px;
					background-color: #fff;
					color: $main-primary-color;
					cursor: pointer;
					
					&::after {
						font-family: bootstrap-icons;
						font-size: 26px;
						line-height: 26px;
						width: 40px;
						height: 40px;
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						content: "\f285";
						transition: background-color .3s ease;
					}
					
					&:hover {
						&::after {
							background-color: $main-primary-color;
							transition: background-color .3s ease;
							color: #fff;
							box-shadow: 0 0 4px 2px rgba(255, 255, 255, .6);
						}
					}
					
					@media(max-width: 1000px) {
						display: none;
					}
				}
				
				.block-caption-inside {
					padding-left: 20px;
					border-left: 1px solid #fff;
					margin-left: 25%;
					position: relative;
					
					@media(max-width: 1100px) {
						margin-left: 0;
					}
					
					a.href-title {
						text-decoration: none;
						
						&:hover, &:focus {
							text-decoration: none;
							.slider-caption-title {
								padding-left: 15px;
								margin-right: -15px;
								transition: all .3s ease;
								@media(max-width: 767px) {
									padding-left: 5px;
									margin-right: -5px;
								}
								&::before{
									transition: width .3s ease;
									width: 15px;
								}
							}
						}
						
					}
					
					.slider-caption-title {
						font-size: 43px;
						line-height: 38px;
						font-weight: 700;
						text-transform: uppercase;
						color: #fff;
						margin-bottom: 15px;
						transition: all .3s ease;
						position: relative;
						padding-top: 15px;
						
						@media(max-width: 1100px) {
							font-size: 20px;
							line-height: normal;
						}
						@media(max-width: 767px) {
							font-size: 18px;
						}
						@media(max-width: 500px) {
							font-size: 14px;
						}
						
						&::before {
							transition: width .3s ease;
							content: "";
							position: absolute;
							display: inline-block;
							width: 0;
							height: 100%;
							background-color: rgba(255, 255, 255, 1);
							left: -20px;
							bottom: 0;
						}
					}

					.slider-caption-desc {
						font-size: 20px;
						line-height: 35px;
						color: #fff;
						@media(max-width: 1100px) {
							font-size: 16px;
							line-height: normal;
						}
						@media(max-width: 767px) {
							font-size: 14px;
						}
						@media(max-width: 500px) {
							display: none;
						}
					}
				}
				
				@media(max-width: 767px) {
					left: 15px;
					right: 50px;
					bottom: 65px;
					padding: 10px;
				}
			}
			
			.img-slide {

			}
		}
	}
	
	.carousel-indicators {
		[data-bs-target] {
			width: 175px;
			height: 2px;
			margin-right: 15px;
			margin-left: 15px;
			@media(max-width: 1200px) {
				width: 30px;
				margin-right: 5px;
				margin-left: 5px;
			}
		}
		
		.active {
			background-color: rgba(19, 71, 213, .6);
			width: 175px;
			height: 2px;
			@media(max-width: 1200px) {
				width: 30px;
				margin-right: 5px;
				margin-left: 5px;
			}
		}
		
		@media(max-width: 767px) {
			display: none;
		}
	}
	
	#block-efect-header {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(19, 71, 213, 1);
		mix-blend-mode: overlay;
	}
}