.main-error {

  .main-error-code {
    font-size: 80px;
    line-height: normal;
    margin-bottom: 20px;
    @media(max-width: 500px) {
      font-size: 50px;
    }
  }

  .main-error-description {
    margin-top: 30px;
    font-size: 30px;
    line-height: normal;
  }

  .main-btn-one {
    width: auto;
    max-width: 350px;
    margin-top: 30px;

    @media(max-width: 500px) {
      font-size: 14px;
      line-height: normal;
    }
  }
}