.carousel-type-b, .main-blog-posts {
	margin-top: 40px;
	margin-bottom: 40px;
	margin-left: -30px;
	position: relative;
	z-index: 1;
	
	.slick-prev {
		position: absolute;
		left: 10px;
		top: -10px;
		z-index: 2;
		
		@media(max-width: 767px) {
			left: 22px;
		}
		
		&::before {
			font-family: bootstrap-icons;
			font-size: 26px;
			line-height: 26px;
			content: "\f284";
			width: 40px;
			height: 40px;
			background-color: $main-primary-color;
			color: #fff;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			opacity: 1;
			transition: background-color .3s ease;
		}
		
		&:hover {
			&::before {
				background-color: #fff;
				transition: background-color .3s ease;
				box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
				color: $main-primary-color;
			}
		}
	}
	
	.slick-next {
		position: absolute;
		right: 0;
		top: -10px;
		z-index: 2;
		
		@media(max-width: 767px) {
			right: 12px;
		}
			
		&::before {
			font-family: bootstrap-icons;
			font-size: 26px;
			line-height: 26px;
			content: "\f285";
			width: 40px;
			height: 40px;
			background-color: $main-primary-color;
			color: #fff;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			opacity: 1;
			transition: background-color .3s ease;
		}
		
		&:hover {
			&::before {
				background-color: #fff;
				transition: background-color .3s ease;
				box-shadow: 0 0 4px 2px rgba(0, 0, 0, .2);
				color: $main-primary-color;
			}
		}
	}
	
	.slick-dots {
		@media(max-width: 767px) {
			display: none !important;
		}
		
		li {
			width: 175px;
			height: 2px;
			margin-right: 15px;
			margin-left: 15px;
			button {
				width: 175px;
				height: 2px;
				&::before {
					margin-top: 40px;
					position: absolute;
					content: "";
					width: 175px;
					height: 2px;
					margin-right: 15px;
					margin-left: 15px;
					background-color: rgba(22, 21, 21, .5);
					opacity: 1;
					@media(max-width: 1200px) {
						width: 30px;
						margin-right: 5px;
						margin-left: 5px;
					}
				}
			}
			
			&.slick-active {
				button {
					&::before {
						position: absolute;
						content: "";
						background-color: rgba(19, 71, 213, .6);
						width: 175px;
						height: 2px;
						opacity: 1;
						@media(max-width: 1200px) {
							width: 30px;
							margin-right: 5px;
							margin-left: 5px;
						}
					}
				}
			}
		}
	}
	
	.block-item {
		padding-left: 30px;
		
		.block-item-inside {
			background-color: $main-secondary-color;
			padding: 0;
			position: relative;
			overflow: hidden;
			box-sizing: border-box;
			display: inline-block;
			float: left;
			
			.cnt-image {
				width: 100%;
				margin-bottom: 40px;
				
				a {
					text-decoration: none;
					
					&:hover, &:focus {
						text-decoration: none;
					}
				}
				
				img {
					width: 100%;
				}
			}

			.cnt-group {
				min-height: 360px;
				clear: both;

				@media(max-width: 767px) {
					min-height: unset;
				}
			}

			.cnt-title {
				display: block;
				text-decoration: none;
				font-size: 24px;
				line-height: 35px;
				font-weight: 700;
				margin-bottom: 30px;
				padding-left: 15px;
				padding-right: 15px;
				clear: both;
				color: $main-body-text-color;
				position: relative;
				transition: all .3s ease;
				
				@media(max-width: 767px) {
					font-size: 20px;
					line-height: normal;
				}
				
				&:hover, &:focus {
					padding-left: 30px;
					margin-right: -15px;
					transition: all .3s ease;
					&::before{
						transition: width .3s ease;
						width: 15px;
					}
				}
				
				&::before {
					transition: width .3s ease;
					content: "";
					position: absolute;
					display: inline-block;
					width: 0;
					height: 100%;
					background-color: $main-body-text-color;
					left: 0;
					bottom: 0;
				}
			}

			.cnt-description {
				font-size: 20px;
				line-height: 35px;
				margin-bottom: 50px;
				padding-left: 15px;
				padding-right: 15px;
				
				@media(max-width: 767px) {
					font-size: 16px;
					line-height: normal;
				}
			}

			.cnt-nick {
				float: left;
				font-size: 24px;
				line-height: 35px;
				font-weight: 700;
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 50px;
				position: relative;
				
				@media(max-width: 767px) {
					font-size: 20px;
					line-height: normal;
					padding-bottom: 20px;
				}

				&::after {
					content: "";
					position: absolute;
					left: 15px;
					bottom: 30px;
					width: 100px;
					height: 1px;
					background-color: $main-primary-color;
					
					@media(max-width: 767px) {
						display: none;
					}
				}
			}
			
			.cnt-date {
				float: right;
				color: #ACACAC;
				font-size: 24px;
				line-height: 35px;
				font-weight: 400;
				font-family: $main-primary-font-family;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}