#header-intro {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  .hdi-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
  }

  .hdi-h1 {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 48px;
    line-height: normal;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    padding: 30px 30px 25px 17%;
    margin-bottom: 0;
    background-color: rgba(1, 5, 17, 0.25);
    backdrop-filter: blur(10px);
    max-width: 60%;
    min-width: 50%;
    @media(max-width: 1200px) {
      font-size: 32px;
      padding: 15px 15px 10px 17%;
    }
    @media(max-width: 767px) {
      max-width: unset;
      width: 100%;
      font-size: 32px;
    }
    @media(max-width: 575px) {
      padding: 15px;
    }
    @media(max-width: 500px) {
      font-size: 18px;
      padding: 10px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 20%;
      margin-left: -20px;
      width: 2px;
      height: 60%;
      background-color: rgba(255, 255, 255, .5);
    }
  }

  #block-efect-header {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(19, 71, 213, 1);
    mix-blend-mode: overlay;
    @media(max-width: 767px) {
      background-color: rgba(19, 71, 213, .3);
    }
  }

}