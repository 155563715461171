.map-poland {

  position: absolute;
  right: auto;
  bottom: -130px;

  @media(max-width: 1220px) {
    bottom: auto;
    margin-left: -100px;
    margin-top: -200px;
  }

  @media(max-width: 1050px) {
    margin-left: -140px;
    margin-top: -50px;
    & > svg {
      width: 600px;
      height: auto;
    }
  }

  @media(max-width: 767px) {
    display: none;
  }

  #p-slask:hover, #p-slask:focus {
    fill: #88b4ea;
  }
  #p-malopolska:hover, #p-malopolska:focus {
    fill: #88b4ea;
  }
}