.blocks-type-c {
	margin-top: 18px;
	margin-bottom: 18px;
	
	.block-item {
		position: relative;
		width: 100%;
		padding: 15px;
		background-color: $main-secondary-color;
		margin-top: 12px;
		margin-bottom: 12px;
		min-height: 835px;

		@media(max-width: 767px) {
			min-height: auto;
		}
		
		.cnt-image {
			width: 100%;
			height: 240px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			
			@media(max-width: 767px) {
				height: 160px;
			}
			
			&::after {
				content: "";
				position: absolute;
				width: 80px;
				height: 1px;
				background-color: $main-primary-color;
				left: 0;
				bottom: 0;
				margin-left: calc(50% - 40px);
			}
		}
		
		.cnt-title {
			font-size: 24px;
			line-height: 35px;
			font-weight: 700;
			text-align: center;
			margin-top: 70px;
			
			@media(max-width: 767px) {
				font-size: 20px;
				line-height: normal;
				margin-top: 40px;
			}
		}
		
		.cnt-description {
			font-size: 20px;
			line-height: 35px;
			text-align: center;
			margin-top: 40px;
			
			@media(max-width: 767px) {
				font-size: 16px;
				line-height: normal;
				margin-top: 20px;
			}
		}
	}
}