.accordion {
	margin-top: 40px;
	margin-bottom: 30px;
	
	h2 {
		&::after {
			display: none;
		}
	}

	h3 {
		font-size: $main-primary-font-size;
		line-height: $main-primary-line-height;
		color: $main-body-text-color;
		font-weight: 400;
		display: inline-block;
		margin-right: 6px;
		margin-left: 6px;
		margin-bottom: 0;
		text-transform: none;
	}
	
	.accordion-item {
		border: none;
		background-color: $main-secondary-color;
		margin-bottom: 10px;
		
		.accordion-header {
			
			.accordion-button {
				background-color: $main-secondary-color;
				font-size: 20px;
				line-height: 35px;
				transition: background-color .3s ease; 
				
				@media(max-width: 767px) {
					font-size: 16px;
					line-height: normal;
					flex-direction: column;
				}
				
				&:hover, &:focus {
					background-color: $main-primary-color;
					transition: background-color .3s ease; 
					color: #fff;
					span {
						color: #fff;
					}
					a {
						color: #fff;
					}
					h3 {
						color: #fff;
					}
				}
				
				&::after {
					font-family: bootstrap-icons;
					content: "\f282";
					background-color: #fff;
					color: $main-primary-color;
					width: 25px;
					height: 25px;
					background-image: none;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
				
				span {
					font-weight: 700;
					color: $main-primary-color;
					
					&::after {
						content: "\00a0";
					}
				}
				
				a {
					display: inline-block;
					margin-left: 8px;
					color: $main-primary-color;
				}
			}
		}
	}

	ul {
		list-style-type: square;
	}
}