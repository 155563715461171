#main-top-bar {
	transition: max-width .3s ease;
	
	&.main-nav-top-sticky {
		padding-right: 60px;
		position: fixed;
		max-width: 100%;
		width: 100%;
		top: 0;
		z-index: 2000;
		box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);
		background-color: rgba(19, 71, 213, .95);
		transition: max-width .3s ease;
		
		#logo-container {
			background-color: rgba(255, 255, 255, .9);
		}
		
		#main-menu-top {
			padding-top: 2px;
			border-bottom: none;
			transition: border-bottom .3s ease;

			ul#menu-top-ul {
				padding: 0;
			}
		}
		
		#main-menu-top, #logo-container {
			height: 70px;
			transition: height .3s ease;
		}

		&.main-wp-bar {
			margin-top: 32px;
		}
	}
	
}