.blocks-type-a {
	margin-top: 50px;
	position: relative;
	z-index: 1;
	
	.block-item {
		width: 100%;
		padding: 15px;
		margin-top: 50px;
		background-color: $main-secondary-color;
		min-height: 400px;

		@media(max-width: 767px) {
			min-height: unset;
		}
		
		& > a {
			text-decoration: none;
			
			&:hover, &:focus {
				text-decoration: none;
			}
			
			&:hover, &:focus {
				.cnt-title {
					margin-left: 5px;
					transition: margin-left .3s ease;
					&::after {
						margin-left: 15px;
						transition: margin-left .3s ease;
					}
				}
			}
		}
		
		@media(max-width: 767px) {
			margin-top: 20px;
		}
		
		.cnt-image {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 160px;

			img {
				max-width: 300px;
				max-height: 160px;
			}
		}
		
		.cnt-title {
			transition: margin-left .3s ease;
			font-size: 22px;
			line-height: 26px;
			font-weight: 700;
			color: #000;
			position: relative;
			overflow: hidden;
			margin-top: 15px;
			
			@media(max-width: 767px) {
				font-size: 22px;
				line-height: normal;
			}
			
			&::after {
				transition: margin-left .3s ease;
				content: "";
				position: absolute;
				display: inline-block;
				width: 100%;
				height: 1px;
				margin-left: 30%;
				background-color: $main-primary-color;
				bottom: 7px;
			}
		}
		
		.cnt-description {
			font-size: 16px;
			line-height: 32px;
			margin-bottom: 15px;
			
			@media(max-width: 767px) {
				font-size: 16px;
				line-height: normal;
			}
		}
	}
}