.breadcrumb-container {

  ul, ol {
    padding-left: 0;
    li {
      a {
        color: $main-body-text-color;
        text-decoration: none;
        &:hover, &:focus {
          text-decoration: underline;
        }
      }
      &:last-child {
        & > a {
          color: $main-primary-color;
        }
      }
    }
  }
}