#main-div-body {
	width: 100%;
	height: auto;
	display: inline-block;
	padding-top: $main-div-body-padding-top;
	background-color: #fff;

	a {
		&[href^="tel:"] {
			font-size: 42px;
			line-height: normal;
			font-weight: 700;
			text-decoration: none;

			@media(max-width: 767px) {
				font-size: 28px;
			}

			&:hover, &:focus {
				text-decoration: underline;
			}
		}
		&[href^="mailto:"] {
			color: $main-primary-color;
			font-weight: 400;

			&:hover, &:focus {
				text-decoration: none;
			}
		}
	}

	.text-sub-title-1 {
		font-size: 28px;
		line-height: normal;
		font-weight: 700;
		text-transform: uppercase;
	}

	.text-format-1 {
		color: #1347D5;
		font-weight: 700;
	}
}